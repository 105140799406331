@import '~bootstrap/scss/bootstrap';
@import 'variables.scss';

.navbar {
  background-color: $eg-purple;
  &__logo {
    max-height: 30px;
  }
}

.body {
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) {
  // For regular screens larger than iPad
  .main-unshifted {
    margin-left: 0px;
    margin-top: 55px;
    padding: 30px 55px;
    transition: 300ms;
  }

  .main-shifted {
    margin-left: 250px; // Same as the width of the sidebar
    // margin-top: 55px;
    padding: 30px 55px;
    transition: 300ms;
  }
}

@media (max-width: 768px) {
  // For smaller screens smaller than iPad
  .main-unshifted {
    margin-left: 0px;
    margin-top: 55px;
    padding: 15px 35px; // Smaller padding for smaller screen
    transition: 300ms;
  }

  .main-shifted {
    margin-left: 0px; // Keep the 0 margin and allow overlaying
    // margin-top: 55px;
    padding: 15px 35px; // User shouldn't be viewing menu and content at the same time
    transition: 300ms;
  }
}
