@import '~bootstrap/scss/bootstrap';
@import 'variables.scss';

.card {
  border-color: $eg-purple;
  border-style: solid;
  border-width: thin;
  box-shadow: 0 0 10px lightgray;
  max-width: 1000px;
  margin: auto;
}

.card-header {
  color: $eg-purple;
  background-color: #f7f7f7;
  text-align: center;
  font-size: large;
  font-weight: 500;
}

.btn {
  &-primary {
    background-color: $eg-purple;
    border-color: $eg-purple;
  }

  &-primary:hover {
    background-color: $eggplant;
    border-color: $eggplant;
  }

  &-primary:focus {
    background-color: $eg-purple;
    border-color: $eg-purple;
  }

  &-outline-primary {
    color: $eg-purple;
    background: white;
    border: solid 1px $eg-purple;
    font-weight: 400;
  }

  &-outline-primary:hover {
    color: $eg-purple;
    background: white;
    box-shadow: 3px 3px $lavender;
    font-weight: 500;
    border: solid 2px $eg-purple;
    border-color: $eg-purple;
  }
}

.modal-60w {
  width: 60%;
  max-width: none !important;
}

.modal-80w {
  width: 80%;
  max-width: none !important;
}
