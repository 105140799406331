.nav {
  position:relative !important;
  width: 100%;
}

.centered {
  text-align: center;
}

.flex {
  display: flex;
}

.maxWidth {
  width: 100%;
}

.font20{
  font-size: 20px;
}

#pinInput{
  padding-top: 5px;
  color: purple;
  width: 10vw;
}

.pinText{
  padding-top: 5px;
  font-size: 20px;
  padding-right: 10px;
}

#pinHolder{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#extraCreditInput{
  width: 60px;
}

#noPadding {
  padding: 0;
}

#paddingTop {
  padding-top: 10px;
}

.searchBy {
  padding-top: 5px;
  text-align: center;
}

#grayText {
  color: gray;
}

#groupsResultList  {
  font-size: 1rem;
  list-style-type: circle;
}

#searchBy {
  width: 20%;
  background-color: #57068c;
  color: white;
  margin-right: 5px;
}

.star {
  color: red;
}

.gray {
  color: rgb(122, 122, 122);
}

.extraCredContainer {
  display: flex;
}

#bottomtext {
  margin-left: 5px;
  margin-top: 7px;
}
