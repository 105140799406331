.report-section-title {
  margin-top: 15px;
  font-size: 26px;
  font-weight: bold;
}

.worktype-dropdown {
  margin-bottom: 15px;
}

.table-label {
  width: 20%;
  text-align: center;
  vertical-align: bottom;
  font-weight: bold;
}

.label {
  display: inline-block;
  text-align: left;
}
