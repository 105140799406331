.announcement {
  margin-bottom: 20px;

  .card-header {
    text-align: left;
  }

  &__title-input {
    background-color: transparent;
    border: 0;
    color: white;
    float: left;
    width: 100%;
  }
}

.btn {
  position: relative;
  margin-right: 15px;
}
