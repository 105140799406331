.student-name-td {
  font-weight: bold;
}

.gradebook-categories-tabs {
  margin-top: 2%;
}

.section-title {
  margin-top: 2%;
  text-align: center;
}
