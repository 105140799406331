.logo {
  max-height: 50px;
}

.dev-userSelector {
  position: block;
  bottom: 15px;
  padding: 15px;
}

.loginButton {
  position: absolute;
  right: 20px;
  top: 10px;
}

.redirectButton {
  position: absolute;
  right: 190px;
  top: 13px;
}

.nav {
  position: fixed;
  width: 100%;
}
