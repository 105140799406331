.alert {
    text-align: center;
    font-size: 1em !important;
}

.alert .text {
    font-size: .9em !important;
    margin: 0 0 !important;
}

.alert-heading {
    font-size: 1.2em !important;
    text-align: center;

}