.report-type-heading {
  padding-top: 2%;
  padding-bottom: 2%;
  text-align: center;
}

.pagination-tabs {
  overflow: scroll;
  overflow-y: hidden;
}
